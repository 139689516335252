<template>
<div>

    <Nuevo  v-if="NuevoActivo" :NuevoActivo="NuevoActivo" :Accion="Accion" :Item="Item" @NuevoActivo="NuevoActivo = $event"/>

  <v-data-table
    :headers="headers"
    :items="arreglo"
    :items-per-page="5"
    :search="busqueda"
    dense
    sort-by="id"
    sort-desc="true"
    class="elevation-12 mt-5"
    :loading="TablaVacia"
    loading-text="Cargando... Espere por favor"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >

      <v-toolbar-title>{{titulo}}</v-toolbar-title>

        <v-spacer></v-spacer>
        <!--busqueda-->
        <v-text-field
            v-model="busqueda"
            append-icon="mdi-magnify"
            label="BUSQUEDA"
            single-line
            hide-details
        ></v-text-field>
        <!--busqueda-->
        <v-spacer></v-spacer>

        <v-btn
          color="success"
          dark
          @click="newItem()"
        >
          NUEVO
        </v-btn>

      </v-toolbar>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          v-on="on"
          small
          @click="mostrarItem(item)"
        >
          mdi-eye
        </v-icon>
      </template>
      <span>Ver</span>
    </v-tooltip>
   
    </template>

  </v-data-table>
  </div>
</template>

<script>
  import Nuevo from "./nuevo-transferencia"
  import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

  export default {
    components: {
        Nuevo,
    },

    props:['titulo','headers','arreglo'],

    created () {
      this.initialize()
    },

    data: () => ({
      checkitems: false,
      busqueda: '',

      Item: {
              id: 0, 
              punto_venta: '', 
              fecha: '', 
              medio_pago: '', 
              cuenta: '',
              tipo_cuenta: 'Ahorros',
              valor: '',
              numero_celular: '', 
              pin: ''
          },
      Accion: null,

      TablaVacia: null,
      items: [],
      
      NuevoActivo: false,
    }),

    watch:{
      arreglo(){
          if(!!this.arreglo){
            if(this.arreglo.lenght > 0){
              this.TablaVacia = true
            }else{
              this.TablaVacia = false
            }
          }
        },
    },

    methods: {
      ...mapMutations([]),
      ...mapActions([]),

      initialize () {
        
      },

      newItem (){
        this.Item= {
          id: 0, 
          punto_venta: '', 
          fecha: '', 
          medio_pago: '', 
          cuenta: '',
          tipo_cuenta: 'Ahorros',
          valor: '',
          numero_celular: '', 
          pin: ''
        }
        this.NuevoActivo = true
        this.Accion = 'Nuevo'
      },

      mostrarItem (item) {
        this.Item = Object.assign({}, item);
        this.NuevoActivo = true;
        this.Accion = 'Editar';
      },
    },

    computed: {
      ...mapState([]),
      ...mapGetters([]),
    },
  }
</script>