<template>
  <div class="transferencias">
    <tabla titulo="Transferencias" 
          :headers = "headers"
          :arreglo = "Transferencias.items"
            />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import tabla from '@/components/tramites/billetera/index-transferencias';

export default {
  name: 'Transferencias',

   components: {
    tabla
  },
  
  created(){
    this.ConsultarTransferencias()
  },

  data: () =>{
    return{
    headers: [
        {
          text: 'ID',
          align: 'start',
          value: 'id',
        },
        { text: 'FECHA', value: 'fecha_format' },
        { text: 'MEDIO DE PAGO', value: 'medio_pago_format' },
        { text: 'CUENTA', value: 'cuenta' },
        { text: 'TIPO DE CUENTA', value: 'tipo_cuenta' },
        { text: 'VALOR', value: 'valor_format' },
        { text: 'NÚMERO CELULAR', value: 'numero_celular' },
        { text: 'ACCIONES', value: 'actions', sortable: false },
      ],

    }
  },

  methods: {
    ...mapActions('billeteras',['ConsultarTransferencias']),
  },

  computed:{
      ...mapGetters('billeteras',['Transferencias'])
  }
}
</script>
